import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "images/home/hero.jpg" }) {
        ...heroImage
      }
      logo: file(relativePath: { eq: "images/logo.png" }) {
        ...logoImage
      }
      boilers: file(relativePath: { eq: "images/home/services/boilers.jpg" }) {
        ...featureImage
      }
      sanitair: file(
        relativePath: { eq: "images/home/services/sanitair.jpg" }
      ) {
        ...featureImage
      }
      airconditioning: file(
        relativePath: { eq: "images/home/services/airconditioning.jpg" }
      ) {
        ...featureImage
      }
      onderhoud: file(
        relativePath: { eq: "images/home/services/onderhoud.jpg" }
      ) {
        ...featureImage
      }
      nieuwbouw: file(
        relativePath: { eq: "images/home/services/nieuwbouw.jpg" }
      ) {
        ...featureImage
      }
      verwarming: file(
        relativePath: { eq: "images/home/services/verwarming.jpg" }
      ) {
        ...featureImage
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home - Uytterhaegen Jan BV" pathname="/" />
      <BackgroundImage
        Tag="section"
        className="main-hero"
        fluid={data.main.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h2>Jan Uytterhaegen BV</h2>
            <h3>Ervaring en professionele kwaliteit zijn ons handelsmerk</h3>
          </div>
        </div>
      </BackgroundImage>
      <div className="container">
        <div className="wrapper">
          <div className="content">
            <section>
              <h1>Onze specialisaties</h1>
              <div className="cards">
                <div className="card">
                  <Link to="/airconditioning">
                    <Img
                      fluid={data.airconditioning.childImageSharp.fluid}
                      alt={data.airconditioning.name}
                      className="card-image"
                    />
                    <h2>Airconditioning</h2>
                  </Link>
                  <p>
                    Om uw wooncomfort tijdens de warme zomermaanden te behouden,
                    kunnen wij voor een{" "}
                    <strong>perfecte airconditioning</strong> zorgen. Een goede
                    luchtkwaliteit, een bescheiden uiterlijk, minimaal geluid en
                    gebruiksvriendelijke bediening, daar zorgen we voor.
                  </p>
                  <p>
                    De nieuwe generatie airco’s zijn daarenboven intelligenter ,
                    milieuvriendelijker en zuiniger.
                  </p>
                </div>

                <div className="card">
                  <Link to="/verwarming">
                    <Img
                      fluid={data.verwarming.childImageSharp.fluid}
                      alt={data.verwarming.name}
                      className="card-image"
                    />
                    <h2>Verwarming</h2>
                  </Link>
                  <p>
                    Meer dan
                    <strong>
                      {" "}
                      50% van uw energiefactuur gaat naar verwarming
                    </strong>
                    . Uw oude ketel vervangen door een nieuw toestel is een
                    vanzelfsprekende oplossing om te besparen op uw
                    energiefactuur want weet dat de goedkoopste energie, de
                    energie is die u niet verbruikt.
                  </p>
                </div>

                <div className="card">
                  <Link to="/nieuwbouw">
                    <Img
                      fluid={data.nieuwbouw.childImageSharp.fluid}
                      alt={data.nieuwbouw.name}
                      className="card-image"
                    />
                    <h2>Nieuwbouw en renovatie</h2>
                  </Link>
                  <p>
                    Nieuwbouw of totale renovatie, maak gebruik van de
                    gelegenheid om de{" "}
                    <strong>meest efficiënte verwarmingsoplossing</strong> voor
                    uw project te definiëren samen met verwarmingselementen die
                    beantwoorden aan al uw interieureisen.
                  </p>
                </div>

                <div className="card">
                  <Link to="/boilers">
                    <Img
                      fluid={data.boilers.childImageSharp.fluid}
                      alt={data.boilers.name}
                      className="card-image"
                    />
                    <h2>Boilers</h2>
                  </Link>
                  <p>
                    Er zijn verschillende manieren om u te voorzien van
                    warmwater . Doorstromers, gasboilers, zonneboilers,
                    elektrische boilers,… . Afhankelijk van de situatie zoeken
                    wij een oplossing voor een zo{" "}
                    <strong>hoog mogelijk comfort.</strong>
                  </p>
                </div>

                <div className="card">
                  <Link to="/sanitair">
                    <Img
                      fluid={data.sanitair.childImageSharp.fluid}
                      alt={data.sanitair.name}
                      className="card-image"
                    />
                    <h2>Sanitair</h2>
                  </Link>
                  <p>
                    Voor oplossingen om de{" "}
                    <strong>kwaliteit van je water</strong> te verbeteren, om
                    water te besparen of voor de renovatie van uw badkamer, kan
                    je bij ons terecht.
                  </p>
                </div>

                <div className="card">
                  <Link to="/onderhoud">
                    <Img
                      fluid={data.onderhoud.childImageSharp.fluid}
                      alt={data.onderhoud.name}
                      className="card-image"
                    />
                    <h2>Onderhoud en herstellingen</h2>
                  </Link>
                  <p>
                    Het spreekt voor zich dat verbrandingstoestellen onderhouden
                    moeten worden. Het kuisen en afstellen van de brander zorgt
                    voor een
                    <strong>optimaal rendement en langere levensduur</strong>
                    van uw toestel.
                  </p>
                  <p>
                    Wij doen onderhoud en herstellingen op traditionele
                    toestellen van alle merken.
                  </p>
                </div>
              </div>
            </section>
            {/* <section>
              <h1>Enkele van onze realisaties</h1>
              <Lightbox
                thumbs={data.thumbs.edges}
                images={data.realisaties.edges}
              />
            </section> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
